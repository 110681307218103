.software {
  .top {
    background: #fafafa;
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    padding: 30px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .small {
      color: #0bb791;
      font-size: 14px;
    }
    .big {
      font-weight: 700;
      font-size: 25px;
    }
  }

  .softwares{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 5%;

    .s-div{
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;

        .first{
            background: transparent;
            border-bottom-left-radius: 8px;
            border-top-left-radius: 8px;
            padding: 3% 1%;
            display: flex;
            flex-direction: column;
            gap: 17px;
            flex: 1;
            border: 1px solid #fafafa;
            height: 200px;


            .head{
                font-weight: 700;
                font-size: 30px;
            }

            .cnt{
                font-size: 16px;
                color: white
            }

        }

        .sec{
            flex: 1;
            background: #fafafa;
            padding: 10px;
            height: 200px;
            border-bottom-right-radius: 8px;
            border-top-right-radius: 8px;


            
            img{
                height: 190px;
                max-width: 100%;
            }
        }
    }
  }
}

@media (max-width:900px){
  .software{
    .top{
      padding: 15px;
      gap: 10px;
      .big{
        font-size: 1.3rem;
      }
    }

    .softwares{
      padding: 2%;
  
      .s-div{
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
  
          .first{
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              padding: 3% 1%;
  
  
              .head{
                  font-weight: 700;
                  font-size: 30px;
              }
  
              .cnt{
                  font-size: 16px;
              }
  
          }
  
          .sec{
              flex: 1;
              background: #fafafa;
              padding: 10px;
              height: 200px;
              border-bottom-right-radius: 8px;
              border-bottom-left-radius: 8px;
  
  
              
              img{
                  height: 190px;
                  max-width: 100%;
              }
          }
      }
    }
  
  }
}