
.dashboard{
    

    .cad22{
       position: relative; /* Creates positioning context */
       width: 300px;
       height: 200px;
       .cad23 {
        position: absolute;
        // width: 100%;
        height: 14rem;
        // background: #f0f0f0;
        z-index: 1;
       }
       .cad24 {
        position: absolute;
        top: 30px;
        left: -48px; /* Adjust positioning */
        // width: 80%;
        height: 80%;
       
        z-index: 2; /* Higher than back-div */
        // box-shadow: 0 0 10px rgba(0,0,0,0.1);
       }
    }

    .modal-container1 {
        position: relative; /* Establishes a positioning context */
        display: inline-block; /* Ensures the container wraps just around its content */
      }
      
      .mini-modal1 {
        position: absolute;
        top: 100%; /* Position it just below the container (button) */
        left: -5.5rem;
        margin-top: 5px; /* Optional spacing between button and modal */
        padding: 3px;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        z-index: 100;
        border-radius: 5px;

        button {
            background-color: rgba(104, 102, 102, 0.544);
            border: none;
            width: 100%;
        }

        hr {
            margin: 2px;
        }
      }


    .modal-content{
        background-color: transparent;
    }

    .guZdik{
        height: 20rem;
    }

    .btn-sub{
        background: #096029;
        color: #fff;
        width: 80px;
        border-radius: 6px;
        margin: 0 auto;
    }
    .col{
        display: flex;
        flex-direction: column;
    }
    .bold{
        font-weight: bold;
        font-size: 17px;
    }
    .small{
        font-weight: 500;
        font-size: 15px;
        color: #929191;
        display: flex;
        gap: 7px;
        align-items: center;
    }
    .small:hover{
        color: whitesmoke;
    }
    .kydb:hover{
        background-color: blue;
    }
    .scs-div{
        width: 280px;
        height: 280px;
        background: #e8e9eb;
        color: green;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        position: relative;

        .scs-span{
            font-size: 17px;
            font-weight: 700;
        }

        .scs-cnc{
            position: absolute;
            right: 3%;
            top: 5%
        }

        img{
            width: 80%;
            border-radius: 100%;
        }

    }
    .inpt-purchase{
        height: 60px;
        border: 1px solid #141313;
        border-radius: 4px;
        background: #000;
        width: 100%;
        color: #929191;
        margin-top: 5px;
        resize: none;
        border: 1px solid #28292c;
    }

    
    .h2s{
        font-weight: 700;
        font-size: 2.3rem;
    }

    .roww{
        display: flex;
        flex-direction: row;
    }
    .navba{
        color: #fff;
        justify-content: space-between;
        align-items: center;
        background-color: #010a12;
        padding: 5px ;
        height: 60px;

        .nav-col{
            gap: 12px;
            .logo{
                width: 150px;
            }
            .n-btn{
                background: transparent;
                border: 1px solid rgba(53,184,114,.25);;
            }
        }
    }
    // .sidebar{
    //     color: #fff;
    //     padding: 15px 1px 3px;
    //     gap: 50px;
    //     height: calc(100vh - 10px);
    //     display: flex;
    //     // flex-direction: column;
    //     // justify-content: space-between;
    //     position: sticky;
    //     top: 40px;
    //     bottom: 0;
      

    //     .small{
    //         font-size: 10px;
    //     }
    //     .bal{
    //         font-size: 20px;
    //         margin-top: -10px;
    //     }

    //     .sidebar-others{
    //         .drop-d{
    //             background-color: transparent;
    //             border: none;
    //             outline: none;
    //             font-size: 19px;
    //             color: #fff;
    //             text-align: left;
    //         }
    //     }
    // }
    .sidebar{
        
        padding: 15px 1px 3px;
        gap: 50px;
        height: calc(100vh - 10px);
        display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        position: sticky;
        top: 40px;
        bottom: 0;

        .logo{
            width: 150px;
        }

        .d-board{
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            width: 80%;
            padding:  10px;
            background: linear-gradient(to right, #39a34b, #0b6f59 );
            
            .d-board-btn{
                color: #fff;
                display: flex;
                align-items: center;
                background: transparent;
                border: none;
                outline: none;
                gap: 5px;
                resize: none;
            }
        }
        .sidebar-others{
            gap: 20px;
            .users, .req{
                border-top: 1px dotted #28292c;
                padding: 15px 10px ;
                gap: 8px;
            }
            .l-out{
                padding: 15px 10px ;  
            }
            .sb-btn{
                background-color: transparent;
                border: none;
                outline: none;
                font-weight: 400;
                font-size: 15px;
                color: #28292c;
                display: flex;
                align-items: flex-start; 
                margin-left: -5px;       
            }
            .sb-btn:hover{
                color: whitesmoke;
            }
        }

      

        .bal{
            font-size: 20px;
            margin-top: -10px;
        }

    }

    .main{
        width: 100%;
        padding: 2% 0 10%;
        gap: 25px;
        height: 100vh;
        max-height: fit-content;
        overflow: scroll;
        &::-webkit-scrollbar {
            display: none;
          }          

        
        .kyc{
            background: #f2f4f9;
            padding: 10px 8px;
            max-width: 100%;
            gap: 10px;
            align-items: center;
            border-radius: 10px;
        }
        .b-div{
            display: flex;
            gap: 12px;
            flex-direction: row;
            width: 100%;
            padding: 0px 10px;

            .bal-div {
                background: #4e4ef9;
                padding: 5px 10px;
                color: #fff;
            }
            .v-btn{
                background: transparent;
                padding: 10px;
                border: none;
                outline: none;
                resize: none;
                font-weight: 500;
                color: #fff;
                font-size: 17px;
                text-align: left;
                border: 1px solid #051c31;
                border-radius: 8px;
                box-shadow: 0 4px 10px rgba(53, 68, 184, 0.01);

            }
            .acc-div{
                width: 50%;
                padding: 10px;
                padding: 15px 10px;
                background: #010a12;
                justify-content: space-between;
                height: 280px;
                box-shadow: 0 4px 10px rgba(53, 68, 184, 0.1);

                .acc-f {
                    gap: 20px;
                    color: #fff;
                }

                .acc-sec{
                    width: 30%;
                    align-items: center;
                    .gif{
                        width: 90%;
                    }
                }
            }

            .ot-bal{
                width: 50%;
                gap: 20px;

                .ot-bal-div{
                    background: #010a12;
                    color: #fff;
                    padding: 10px;
                    height: 100px;
                    gap: 10px;
                    box-shadow: 0 4px 10px rgba(53, 68, 184, 0.1);

                    
                }
            }
        }

        .rf-auth{
            padding: 10px 15px;
            gap: 10px;

            .ref{
                border-radius: 4px;
                border: 1px solid #28292c;
                width: 50%;
                // height: 190px;
                color: #fff;
                gap: 22px;
                padding: 10px;

            }
            .auth{
                border-radius: 4px;
                border: 1px solid #28292c;
                width: 50%;
                color: #fff;
                // height: 190px;
                gap: 22px;
                padding: 10px;

                .at-row{
                    justify-content: space-between;
                }
            }
        }

        .transactins {
            width: 100%;            
            .tr-head{
                background: #010a12;
                padding:  8px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .tr-span{
                    color: #f2f4f9;
                    font-size: 18px;
                    font-weight: 600;
                }
    
            }
            .tr-map{
                justify-content: space-between;
                padding: 10px 10%;

                .tr-s-c{
                    color: #f2f4f9;
                    width: 25%;
                }
            }
        }
    }
    .all-transactions{
        width: 100%; 
        display: flex;
        // align-items: center;
        flex-direction: column;           
        .tr-head{
            background: #010a12;
            padding:  8px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .tr-span{
                color: #f2f4f9;
                font-size: 18px;
                font-weight: 600;
            }

        }
        .tr-map{
            display: flex;
            justify-content: space-between;
            // padding: 10px 10%;
            // gap: 5%;

            .tr-s-c{
                color: #f2f4f9;
                width: 25%;
            }
        }
    }

    .buy-stock{
        display: flex;
        // flex-direction: column;
        // gap: 30px;
        width: 100%;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        padding: 10px;
        height: 100vh;
        max-height: fit-content;
        overflow: scroll;
        &::-webkit-scrollbar {
            display: none;
          }          

    
        }
        .card{
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: center;
            width: calc(33.33% - 10px); 
            margin: 5px; 
            background: #000;
            border:  1px solid #141313;
            // box-shadow: 0 1px 1px rgba(122, 120, 120, 0.7);
            color: #f2f4f9;
            height: 280px;
            .logo-div{
                padding: 10px;
                height: 60%;
                display: flex;
                flex-direction: column; 
                justify-content: space-evenly; 
                align-items: center;
                .head{
                    font-weight: 700;
                    font-size: 17px;
                } 
                img{
                    border-radius: 100%;
                    height: 70%;
                } 
                .symb{
                    font-size: 12px;
                }
            }
            .bottom-div{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                gap: 10px;


                .roi{
                    width: 100%;
                    background: #010a13;
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;
                }

                button{
                    width: 50%;
                    // background: ;
                    color: white;
                    border-radius: 8px;
                    height: 40px;
                    outline: none;
                    border: none;
                    margin: 0 auto;
                    background: linear-gradient(to right, #39a34b, #096029 );

                }
            }
        }

}


@media (max-width: 900px) {
    .main{
        .transactins {
            margin-top: 14rem;
        }
    }
    .dashboard{
        .h2s {
          font-size: 1.7rem;
        }
        .card {
            width: 100%;
        }
        .buy-stock{
            display: block;
        }
    }
    .bounce {
        width: 291px !important;
    }
    .cadbody {
        display: block!important;
        padding: 2px!important;
        margin-top: 1rem;
    }
    .cad2 {
        text-align: center;
        margin-right: 2rem;
        margin-top: .7rem;
    }
    .cad3 {
        margin-top: 1.5rem;
        margin-left: 0!important;
    }
    .col{
        padding: 5px !important;
    }
}