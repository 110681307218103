@import url("https://fonts.googleapis.com/css?family=League+Spartan");
.navigation {
  background: #ffffff;
  display: flex;
  align-items: center;

  justify-content: space-between;
  padding: 1% 5%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  position: sticky;
  z-index: 100;
  top: 0;

  .btn-secondary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-decoration: none;
    color: #000000;
    font-weight: 500;
    font-family: "League Spartan", sans-serif;
    background-color: transparent;
    border: none;
    margin-top: 5%;
  }
  .dropdown-menu {
    border: none;
    background-color: #fff;
  }
  a {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-decoration: none;
    color: #000000;
    font-weight: 500;
    font-family: "League Spartan", sans-serif;
  }

  .brand-logo {
    width: 200px;
  }

  .flex {
    display: flex;
  }
  .first {
    gap: 23px;

    .nav-links {
      gap: 25px;
      align-items: center;
    }
  }

  .second {
    .log {
      background: #0bb791;
      border-radius: 8px;
      font-weight: 700;
      font-size: 16px;
      border: none;
      outline: none;
      padding: 10px;
      color: #fff;
      width: 80px;
      backdrop-filter: blur(20px);
    }
    .log:hover {
      color: #0bb791;
      background-color: white;
    }
  }
}

@media (max-width: 991px) {
  .navigation {
    .brand-logo {
      width: 180px;
    }

    .first {
      .nav-links {
        display: none;
      }
    }
  }
  .second {
    gap: 10px;

    .mn-btn {
      display: block;
      background: transparent;
      border: 1px solid rgba(53, 184, 114, 0.25);
      border-radius: 8px;
      font-weight: 700;
      font-size: 16px;
      padding: 10px;
      color: #fff;
    }
    .coll {
      flex-direction: column;
      align-items: flex-start;
      height: 100vh;
      padding: 10px;
      gap: 8px;
      position: absolute;
      top: 67px;
      z-index: 1;
      right: 0;
      background: #0b533e;
      width: 215px;

      .dropdown-menu {
        border: none;
        background-color: #fff;
      }

      .nav-link {
        color: #ffffff;
      }

      .btn-secondary {
        margin-left: -10px;
      }
    }
  }
}

#google_translate_element select {
  height: 2rem;
  background-color: rgb(9 9 30);
  border-radius: 0.5rem;
  color: white;
}
.goog-te-gadget {
color: #0d8b5000!important;

}
.goog-te-gadget span {
  display: none;
  
  }
  
