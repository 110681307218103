@media (min-width: 576px){

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
        margin-top: auto;
    }

}



.regy2 {
    width: 20%;
    color: white;
    border: none;
    border-radius: 5px;
    padding: .6rem;
    margin-top: 1rem;
    background-color: green;
  }
  
  
  .regy2:hover {
    cursor: pointer;
    background-color: #fafafa;
    color: #0bb791;  
  }


// @media (min-width: 1201px) {
//     .col-sm-8 {
        
//         width: 96.66666667%;
//         margin-top: 40rem;
//     }
// }

// @media  (min-width: 1224px) {
//     .col-sm-8 {
//         flex: 0 0 auto;
//         width: 66.66666667%;
//         margin-top: 1rem;
//     }
// }



