
.coln {
    background-color: transparent;
}



.page-title-box {
    padding: 24px 0;
}

@media (max-width: 1224px) {
    .lohk {
        margin-right: 1rem!important;
        margin-left: 2rem!important;
    }
    
}

.about_us__modern {
    width: 100%;
    /* background: url(../../assets/merxologo.png)  center 230px; */
    padding: 55px 0 0;
}

@media (max-width:900px) {

    .cadtext{
     text-align: center;
    }
    .lohk{
        width: 100%!important;
        margin-left: 0!important;
    }
 
 }
