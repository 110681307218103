

/* Flash Message Styles */
.flash-message {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 9px 20px;
    border-radius: 8px;
    font-size: 1rem;
    color: white;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: slide-in 1s ease-out, fade-out 1s ease-in 3s forwards;
  }
  
  .flash-message.success {
    background-color: #4caf50cf;
  }
  
  .flash-message.error {
    background-color: #f44336;
  }
  
  .flash-message.info {
    background-color: #2196f3;
  }
  
  .flash-message.warning {
    background-color: #ff9800;
  }
  
  /* Close Button */
  .close-button {
    background: transparent;
    border: none;
    color: white;
    font-size: 1.2rem;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .close-button:hover {
    opacity: 0.8;
  }
  
  /* Animations */
  @keyframes slide-in {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes fade-out {
    to {
      opacity: 0;
    }
  }

  .inpt {
    margin-bottom: 1rem;
  }
  
  


.chat-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    max-width: 600px;
    margin: auto;
    background-color: transparent;
    border-radius: 10px;
    overflow-y: auto;
    height: 500px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .chat-message {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    padding: 10px 15px;
    border-radius: 15px;
    position: relative;
  }
  
  .user-message {
    align-self: flex-end;
    background-color: #4caf50;
    color: white;
    border-bottom-right-radius: 0;
  }
  
  .other-message {
    align-self: flex-start;
    background-color: #e0e0e0;
    color: black;
    border-bottom-left-radius: 0;
  }
  
  .message-info {
    display: flex;
    justify-content: space-between;
    font-size: 0.85em;
    margin-bottom: 5px;
    color: #666;
  }
  
  .message-text {
    font-size: 1em;
    line-height: 1.4;
    word-wrap: break-word;
  }
  
  .sender-name {
    font-weight: bold;
  }
  
  .message-time {
    font-style: italic;
  }
  
  @media (max-width: 600px) {
    .chat-container {
      padding: 10px;
      height: 100vh;
      max-width: 100%;
    }
  
    .chat-message {
      padding: 8px 12px;
    }
  }
  
  


.container2 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px;
    width: 70%;
  }
  
  /* Info box above the table */
  .info-box2 {
    flex: 1 1 100%;
    padding: 15px;
    background-color: #f0f0f078;
    // border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
  }
  
  /* Table wrapper */
  .table-wrapper2 {
    flex: 1 1 100%;
    overflow-x: auto; /* Allow horizontal scrolling for the table */
    background-color: #fff;
    border-radius: 8px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    // padding: 10px;
  }
  
  /* Responsive table styling */
  .responsive-table2 {
    width: 100%;
    border-collapse: collapse;
  }
  
  .responsive-table2 th,
  .responsive-table2 td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }
  
  .responsive-table2 th {
    background-color: #bdc2c7;
    color: #fff;
    font-weight: bold;
  }
  
  .responsive-table2 tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Make table header sticky during scroll */
  .table-wrapper2::-webkit-scrollbar {
    height: 8px;
  }
  
  .table-wrapper2::-webkit-scrollbar-thumb {
    background-color: #0074D9;
    border-radius: 10px;
  }
  
  /* Responsive design for stacking on mobile view */
  @media (max-width: 768px) {
    .container2 {
        width: 100%;
        margin: 1rem 0
    //   flex-direction: column;
    }
  
    .info-box2,
    .table-wrapper2 {
      flex: 1 1 100%;
    }
  
    .responsive-table2 th,
    .responsive-table2 td {
      font-size: 14px;
    }
  }
  
  
  




.responsive-container {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap onto the next line */
    justify-content: space-between; /* Distribute space between items */
    gap: 10px; /* Space between items */
    // padding: 20px;
    background-color: #f8f9fa;
  }
  
  .box {
    flex: 1 1 calc(20% - 10px); /* Each box takes 20% of the width with spacing */
    min-width: 120px; /* Ensure the boxes don’t shrink too small */
    height: 200px;
    background-color: #fff;
    color: white;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    .boxi {
        color: gray;
        font-style: italic;
        font-size: medium;
    }
    .boxa {
        font-weight: bolder;
        font-size: 1.6rem;
    }
  }
  
  .box:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Design for Smaller Screens */
  @media (max-width: 768px) {
    .box {
      flex: 1 1 100%; /* Make each box take full width */
    }
  }
  


.app-container {
    display: flex;
    min-height: 100vh;
    background-color: white;
    overflow: hidden;
  }

  .sidebar::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    border: none;
  }
  
  .sidebar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }
  
  .sidebar::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Round edges */
    display: none;
  }
  
  .sidebar::-webkit-scrollbar-thumb:hover {
    background: #f7f6f6; /* Color when hovered */
  }

  
  .sidebar::-webkit-scrollbar-thumb {
    transition: background 0.3s ease;
  }
  

  
  /* Sidebar Styles */
  .sidebar {
    width: 140px;
    background-color: #1e293b;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 96%;
    margin-top: 1rem;
    margin-left: 1rem;
    border-radius: 5px;
    overflow-y: hidden;
    overflow-x: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 10;
    scrollbar-color: #ffffff45  transparent; /* thumb track */
    scrollbar-width: thin; /* Thin scrollbar */
    scroll-behavior: smooth; /* Smooth scrolling */
    scrollbar-arrow-color: transparent;
  }

  .sidebar:hover {
    overflow-y: scroll;
    box-shadow: 6px 12px 18px rgba(0, 0, 0, 0.3);
  }
  
  .sidebar-content h2 {
    margin: 0;
    margin-bottom: 20px;
  }
  
  .sidebar-content ul {
    list-style-type: none;
    padding: 0;

  }
  
  .sidebar-content ul li {
    margin: 10px 0;
    cursor: pointer;
    padding: 10px;
    transition: all 0.3s;
    text-align: center;

    .hid {
        display: none;
      }
  }
  
  .sidebar-content ul li:hover {
    background-color: #334155;
    border-radius: 4px;

    .hid {
   display: inline-block;
   margin-left: -0.8rem;
  }


  
  }


  .ova {
    margin-left: 9rem;
    position: fixed; /* Makes the container fixed at the bottom */
    bottom: 20px; /* Space from the bottom */
    left: 20px; /* Space from the left */
    z-index: 10; /* Keeps it above other elements */
    background-color: #1e293b; /* Dark background */
    color: white; /* Text color */
    padding: 10px; /* Padding inside the container */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    opacity: 0; /* Start hidden */
    transform: translateY(100%); /* Start below the viewport */
    animation: slide-up 0.5s ease-out forwards; /* Slide up animation */
  }
  
  /* Animation for sliding in from the bottom */
  @keyframes slide-up {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  

  
  
  .close-btn {
    align-self: flex-end;
    background-color: #ef4444;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .close-btn:hover {
    background-color: #dc2626;
  }
  
  /* Main Content Styles */
  .main-content {
    overflow-x: auto;
    flex-grow: 1;
    margin-left: 0;
    padding: 20px;
    transition: margin-left 0.3s ease-in-out;
  }

  
  .main-content.with-sidebar {
    margin-left: 190px;
  }
  
  .toggle-btn {
    // padding: 10px 20px;
    background: transparent;
    color: black;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .toggle-btn:hover {
    background-color: #87888d4f;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .sidebar {
      width: 200px;
    }
  
    .main-content.with-sidebar {
      margin-left: 200px;
    }
  }
  


  .kyc-container {
    padding: 20px;
    max-width: 1200px;
    // margin: auto;
    text-align: center;
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  /* KYC Cards */
  .kyc-requests {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .kyc-card {
    background-color: #1b263b;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .kyc-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
  }
  
  .kyc-card h3 {
    margin-bottom: 10px;
    color: #ffffff;
  }
  
  .kyc-card p {
    margin-bottom: 20px;
    color: #9ba9c3;
  }
  
  /* Buttons */
  button {
    cursor: pointer;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .preview-btn {
    background-color: #0074d9;
    color: #ffffff;
    margin-bottom: 15px;
  }
  
  .preview-btn:hover {
    background-color: #0056a6;
  }
  
  .approve-btn {
    background-color: #28a745;
    color: #ffffff;
    margin-right: 10px;
  }
  
  .approve-btn:hover {
    background-color: #1e7e34;
  }
  
  .cancel-btn {
    background-color: #dc3545;
    color: #ffffff;
  }
  
  .cancel-btn:hover {
    background-color: #a71d2a;
  }
  
  /* Image Preview */
  .image-preview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  
  .image-preview img {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
    z-index: 2;
  }
  
  
  /* Page container */
  .deposit-requests-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
    background: linear-gradient(145deg, #fff, rgb(0 0 0 / 0));
    animation: fadeIn 1.5s ease-in-out;
  }
  
  /* Title styling */
  .title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #69c0ff;
    animation: slideInDown 1s ease;
  }
  
  /* Table container */
  .table-container {
    width: 100%;
    max-width: 1500px;
    // overflow-x: hidden;
    background: #122a4d;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    animation: fadeInUp 1s ease;
    overflow: auto; /* Hides both scrollbars */
  overflow-y: scroll; /* Allows vertical scrolling without showing the scrollbar */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none;
  }
  
  /* Table styling */
  .animated-table {
    width: 100%;
    border-collapse: collapse;
    color: #fff;
  }
  
  .animated-table th,
  .animated-table td {
    padding: 15px;
    text-align: left;
  }
  
  .animated-table th {
    background-color: #1d3557;
    color: #69c0ff;
    font-weight: bold;
  }
  
  .animated-table tbody tr:nth-child(odd) {
    background-color: #14304b;
  }
  
  .animated-table tbody tr:nth-child(even) {
    background-color: #1a3b5d;
  }
  
  .animated-table tbody tr:hover {
    background-color: #2a567a;
    cursor: pointer;
  }
  
  /* Status-specific row styling */
  .status-pending {
    border-left: 4px solid #ffcc00;
  }
  
  .status-approved {
    border-left: 4px solid #00c851;
  }
  
  .status-failed {
    border-left: 4px solid #ff4444;
  }
  
  /* Buttons */
  .approve-button {
    background-color: #00c851;
    color: #fff;
    border: none;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .approve-button:hover {
    background-color: #007e33;
  }
  
  .cancel-button {
    margin-top: 10px;
    background-color: #ff4444;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cancel-button:hover {
    background-color: #cc0000;
  }
  
  .status-indicator {
    font-weight: bold;
    color: #69c0ff;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInDown {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  





.dashboar{
    height: 100vh;
    padding: 10px;
    color: #5c5c5c;


    .he{
        font-weight: 700;
        font-size: 15px;
        color: #929191;
        margin: 0 auto;
    }
    .approve{
        background-color: green;
        border: none;
        outline: none;
        color: #fff;
        border-radius: 6px;
        
    }
    .cancel{
        background: red;
        border: none;
        outline: none;
        color: #fff;
        border-radius: 6px;

    }
    .modal-content{
        background-color: transparent;
        width: 100%;
    }

    .form-control{
        background: #080d22;
        color: #fff;
    }
    .inpt{
        height: 50px;
        border: 1px solid #080d22;
        outline: none;
        background-color: #0e283e;
        border-radius: 4px;
        color: #fff;
        margin-top: 3px;
    }
    .widt{
        width: calc(100% / 6);
    }
    .us-widt{
        width: calc(100% / 5);
    }
    .col{
        display: flex;
        flex-direction: column;
    }
    .bold{
        font-weight: 500;
        font-size: 15px;
        color: #929191;
    }
    .small{
        font-weight: 500;
        font-size: 15px;
        color: #929191;
        display: flex;
        gap: 7px;
        align-items: center;
    }
    
    .roww{
        display: flex;
        flex-direction: row;
    }
    .navba{
        color: #5c5c5c;
        justify-content: space-between;
        align-items: center;
        background-color: #010a12;
        padding: 5px ;
        // position: sticky;
        // top: 0;
        // height: 40px;

        .nav-col{
            gap: 12px;
            .n-btn{
                background: transparent;
                border: 1px solid rgba(53,184,114,.25);
            }
        }
    }
    .sidebar{
        
        padding: 15px 1px 3px;
        gap: 50px;
        height: calc(100vh - 10px);
        display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        position: sticky;
        top: 40px;
        bottom: 0;

        .logo{
            width: 150px;
        }

        .d-board{
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            width: 80%;
            padding:  10px;
            display: flex;
            align-items: center;
            background: linear-gradient(to right, #39a34b, #096029 );
            color: #fff;

        }
        .sidebar-others{
            gap: 20px;
            .users, .req{
                border-top: 1px dotted #28292c;
                padding: 15px 10px ;
                gap: 8px;
            }
            .l-out{
                padding: 15px 10px ;  
            }
            .sb-btn{
                background-color: transparent;
                border: none;
                outline: none;
                font-weight: 400;
                font-size: 15px;
                color: #28292c;
                display: flex;
                align-items: flex-start; 
                margin-left: -5px;       
            }

        }

      

        .bal{
            font-size: 20px;
            margin-top: -10px;
        }

    }
    .main{
        width: 100%;
        gap: 25px;
        height: 100vh;
        overflow: scroll;
        &::-webkit-scrollbar {
            display: none;
          }          

        .top{
            display: flex;
            flex-direction: row;
            gap: 15px;
            padding:  10px;
            
            .box-d{
                width: 100%;
                gap: 15px;
                .boxs{
                    width: 50%;
                    height: 110px;
                    background-color: #010a12;
                    box-shadow: 0 4px 10px rgba(53, 68, 184, 0.1);

                    .pad{
                        padding: 10px;
                        .bd{
                            font-weight: 500;
                            font-size: 16px;
                            color: #fff;
                            opacity: 0.9;
                    
                        }
                        .block{
                            width: 40px;
                            height: 40px;
                            color: #fff;
                            border-radius: 3px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                    .boda{
                        background-color: rgba(53, 68, 184, 0.1);
                        padding: 5px 10px;

                        .sm{
                            font-weight: 500;
                            color: #fff;
                            gap: 10px;
                            align-items: center;

                            .smp{
                                font-weight: 200;
                                font-size: 12px;
                                color: rgb(86, 202, 0);
                            }
                        }
                    }
                }
            }

        }

        .users{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .heading{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 3% 0 0;
                padding:  .7rem 1rem;
                font-weight: 700;
            }
            .users-cnt{
                display: flex;
                flex-direction: column;
                gap: 5px;
                padding:  .7rem 1rem;

                .user-cnt{
                    display: flex;
                    background-color: #17181c;
                    color: #fff;
                    width: 100%;
                    justify-content: space-between;
                    height: 50px;
                    border-radius: 3px;
                    padding:  .7rem 1rem;


                }
            }
        }

    }
        
}

@media (max-width:1010px){
    .dashboar{
        // height: 100%;
        .main{
            .top{
                flex-direction: column;
            }
        }
    }
  }

  @media (max-width: 768px) {
    .conti2 {
   display: block!important;

   .conti3 {
    padding: 0!important;

    .conti4 {
        text-align: left!important;
    }

   }

  }  
  }