@keyframes bounce {
    0% {
transform: scale(1,1) translate(0px, 0px);
}

  30%{
  transform: scale(1,0.8) translate(0px, 10px); 
   }

 75%{
transform: scale(1,1.1) translate(0px, -25px); 
}

100% {
transform: scale(1,1) translate(0px, 0px);
}
}

.bounce {
    float: left;
  text-align:center;
  font-size: 1.5rem;

  border-radius: 5px;
  color: aliceblue;
  margin:0 auto;
  margin-top:100px;
  height:4.5rem;
  width:350px;
  padding:20px;  
  background:rgb(31, 102, 66);
 animation: bounce 0.75s infinite;
}