/* General container styling */
.cont {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    
     /* Heading */
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  h2 {
    margin-bottom: 15px;
  }

  }
  
 
  
  /* Flex container for side-by-side layout */
  .contw {
    display: flex;
    gap: 20px;
    // flex-wrap: wrap; /* Ensures stacking on smaller screens */

    /* Table styling */
  .tablecont {
    flex: 2;
    overflow-x: auto;
    height: 500px;
  }



/* Custom scrollbar styles */
/* Width of the scrollbar */
.tablecont::-webkit-scrollbar {
  width: 10px;
}

/* Track (background of the scrollbar) */
.tablecont::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Thumb (the draggable part of the scrollbar) */
.tablecont::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Thumb on hover */
.tablecont::-webkit-scrollbar-thumb:hover {
  background: #555;
}




  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
   
    padding: 8px;
    text-align: center;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
    
  }
  
  
  /* Form styling */
  .fcont {
    flex: 1;
    min-width: 300px; /* Ensure minimum width for the form */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;

.formg {
    margin-bottom: 15px;


    label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input, select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  }

  }
  
  
  
  .bot {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .bot:hover {
    background-color: #0056b3;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .contw {
      display: block;
      .tablecont {
       margin-bottom: 1.5rem;
      }
    }
    .content-wrapper {
      // flex-direction: column; /* Stack items vertically */
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    }
  
    table, th, td {
      font-size: 0.9rem;
    }
  }
  