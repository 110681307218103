
  
  .user-page {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    width: 100%;
    min-height: 100vh;
    height: 120vh;
    // padding: 20px;
  }
  
  .user-card {
    background: white;
    // border-radius: 10px;
    height: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    // max-width: 400px;
    padding: 20px;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;

    .avtad {
        position: relative;
        display: inline-block;
        width: 100px;
        height: 100px;

        .edit-icon1 {
            position: absolute;
            bottom: 5px;
            right: 5px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            cursor: pointer;
            background-color: white; /* Optional: Add background to make it visible */
            border: 1px solid #ddd; /* Optional border */
            padding: 2px; /* Optional padding */
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow */
            transition: transform 0.2s ease;
          }
          
          .edit-icon1:hover {
            transform: scale(1.1); /* Slightly enlarge on hover */
          }
    }

    p {
        color: black;
    }
    .user1 {
        border-radius: 19px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: .5rem;
         hr {
            margin: 0;
         }
        .subuser {
            display: flex;
            justify-content: space-between;
            padding: 1rem;
        }
    }


    .user2 {
        margin-top: 3rem;
        border-radius: 19px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: .5rem;
         hr {
            margin: 0;
         }
        .subuser {
            display: flex;
            justify-content: space-between;
            padding: 1rem;
        }
    }
  }
  
  .user-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  h2 {
    margin: 10px 0;
  }
  
  .edit-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background 0.3s;
  }
  
  .edit-btn:hover {
    background-color: #0056b3;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    // max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

    h3 {
        color: white;
    }
  }
  
  .form-group {
    margin-bottom: 1px;
    height: 4.5rem;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    // color: white;
  }
  
  input,
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: .5rem;
  }
  
  .save-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .save-btn:hover {
    background-color: #218838;
  }
  
  .cancel-btn {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .cancel-btn:hover {
    background-color: #c82333;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @media (max-width: 768px) { 
    .user-page {
     height: 100vh;
    }
    .user-avatar {
        margin-top: 3rem;
    }
  }
  