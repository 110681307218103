.wrapper {
    width: 95%;
    max-width: 1200px;
    margin: 30px auto;
}

.pricing-table {
    border-radius: 3px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    text-align: center;
}

.pricing-box-2 {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.13);
    border: 2px solid rgba(10, 123, 183, 0.14);
}

.pricing-table h2 {
    font-size: 1.5em;
    padding: 35px 10px;
    margin: 0;
}

.price, .home .pricing-box {
    transition: all ease-out 0.2s;
}


.price {
    background: linear-gradient(-225deg, #52cd45 0%, #2b7acc 53%, rgba(35, 179, 128, 0.6901960784) 100%);
    color: #fff;
    font-size: 2em;
    font-weight: 600;
    position: relative;
    padding: 20px 10px;
    display: block;
}

.btns {
    background: #000000;
    border: 1px solid #000000;
    color: #fff;
    transition: all ease-out 0.2s;
    border-radius: 30px;
    padding: 7px 40px;
    display: inline-block;
    margin-top: 20px;
}

a {
    color: white;
    text-decoration: none;
}

.btns:hover {
    background-color: #35b94c;
}

.pricing-table-divider {
    display: block;
    height: 1px;
    background: rgba(0, 0, 0, 0.08);
    max-width: 80%;
    margin: 20px auto 0;
}

.pricing-table ul {
    color: #ffffff;
    padding: 15px 60px;
    text-align: left;
}


.pricing-table ul li {
    padding: 3px 0;
    font-size: 0.95em;
}

li {
    list-style-type: none;
}

@media (max-width: 900px) {

    .pricing-table {
        display: block;
    }

    .pricing-box-2 {
        margin-top: 1rem;
    }

}