
.page-title-box {
    padding: 24px 0;
}

@media (max-width: 768px) {
    
    
}

.about_us__modern {
    width: 100%;
   
    padding: 55px 0 0;
}