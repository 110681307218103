/* StockApprovalDashboard.css */
.stock-approval-container {
    min-height: 100vh;
    background-color: #f9fafb;
    padding: 2rem;
  }
  
  .dashboard-header {
    margin-bottom: 2rem;
  }
  
  .pending-count {
    background-color: #3b82f6;
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
  }
  
  .search-container {
    position: relative;
    flex: 1;
  }
  
  .search-input {
    width: 100%;
    padding-left: 2.5rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
  }
  
  .search-icon {
    position: absolute;
    left: 0.75rem;
    top: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
    color: #9ca3af;
  }
  
  .filter-select {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
  }

  .table-scroll {
    overflow-x: scroll;
  }
  
  .stock-table {
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .table-header {
    background-color: #f9fafb;
  }
  
  .table-header-cell {
    padding: 1rem 1.5rem;
    text-align: left;
    font-size: 0.875rem;
    font-weight: 600;
    color: #374151;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  
  .table-row {
    transition: background-color 0.2s;
  }
  
  .table-row:hover {
    background-color: #f9fafb;
  }
  
  .table-cell {
    padding: 1rem 1.5rem;
    font-size: 0.875rem;
    color: #374151;
  }
  
  .status-badge {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    color: white;
  }
  
  .status-badge.pending {
    background-color: #eab308;
  }
  
  .status-badge.approved {
    background-color: #22c55e;
  }
  
  .status-badge.cancelled {
    background-color: #ef4444;
  }
  
  .action-button {
    transition: color 0.2s;
  }
  
  .action-button.approve {
    color: #16a34a;
  }
  
  .action-button.approve:hover {
    color: #166534;
  }
  
  .action-button.cancel {
    color: #dc2626;
  }
  
  .action-button.cancel:hover {
    color: #991b1b;
  }
  
  .empty-state {
    padding: 3rem;
    text-align: center;
    color: #6b7280;
  }
  
  @media (min-width: 768px) {
    .header-content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    
    .filter-controls {
      flex-direction: row;
      width: auto;
    }
  }