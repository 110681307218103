
    .FilesDragAndDrop__area {
      width: 300px;
      height: 200px;
      padding: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
      font-size: 24px;
      color: #555555;
      border: 2px #c3c3c3 dashed;
      border-radius: 12px;
  
      
    }
  .area__icon {
        font-size: 64px;
        margin-top: 20px;
      }
      .guZdik {
         height: 10rem!important;
      }