.insight {
  padding: 2% 0;

  a {
    text-decoration: none;
  }
  .insight-banner {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;

    .first {
      flex: 1;
      padding: 1% 5%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      text-align: center;
      .news {
        font-weight: 500;
        font-size: 50px;
      }
      .insight {
        font-weight: 900;
        font-size: 53px;
        color: #0bb791;
      }
      .turn {
        font-weight: bold;
        font-size: 30px;
      }
      .avt {
        font-weight: 400;
      }
      .banner-btns {
        outline: none;
        border: none;
        resize: none;
        padding: 5px 20px;
        text-align: left;
        border-radius: 8px;
        cursor: pointer;
        font-weight: 500;
        font-size: 23px;
        background: #0bb791;
        color: #ffffff;
      }
    }

    .sec {
      flex: 1;

      .banner-img {
        width: 100%;
        max-height: 500px;
        opacity: 1;
      }
    }
  }

  .about_us__modern {
    width: 100%;
    background: url(../about/img/about-us-modern.png) no-repeat center 130px;
    padding: 13px 0 0;
  }

  .news {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 5%;

    .head {
      font-weight: 700;
      font-size: 40px;
      color: whitesmoke;
    }

    .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 15px;
      justify-content: flex-start;
      width: 100%;
      height: auto;
      flex-wrap: wrap;

      .news-item {
        background: transparent;
        width: calc(33.33% - 15px);
        display: flex;
        flex-direction: column;
        gap: 40px;
        box-shadow: 0 0 30px #00000021;
        border-radius: 8px;

        .news-img {
          height: 270px;
          max-width: 100%;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
        }
        .n-flex {
          padding: 10px;

          .news-headline {
            font-weight: 600;
            font-size: 20px;
            color: whitesmoke;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .insight {
    .insight-banner {
      flex-direction: column;

      .first {
        padding: 5%;
        gap: 5px;

        .news {
          font-size: 30px;
        }
        .insight {
          font-size: 35px;
          margin-top: -25px;
        }
        .turn {
          font-size: 25px;
        }
        .banner-btns {
          font-size: 17px;
        }
      }
    }
    .news {
      .flex {
        flex-direction: column;

        .news-item{
          width: 100%;
          gap: 10px;

          .n-flex{
            .news-headline {
              font-size: 17px;
            }
  
          }
        }
      }
    }
  }
}
